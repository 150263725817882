import Glide from "@glidejs/glide";
import imagesloaded from "imagesloaded";
import { mq } from "@/common/config";
// import throttle from "@/util/throttle";

/**
 * ホームページ Hero Slider
 *
 * glide.js
 * https://github.com/jedrzejchalubek/glidejs
 *
 * imagesloaded
 * https://github.com/desandro/imagesloaded
 *
 * @class HeroSlider
 */
class HeroSlider {
  constructor(wrapper = ".hero", slider = ".hero .glide") {
    this.slider = document.querySelector(slider);
    this.wrapper = document.querySelector(wrapper);

    if (!this.slider || !this.wrapper) return;

    this.slide = this.slider.querySelectorAll(".hero__slide");
    this.observer = null;
    this.imgLoad = imagesloaded(`${slider} img`);
    this.isSingle = this.slide && this.slide.length <= 1;

    this.glide = new Glide(this.slider, {
      type: "carousel",
      focusAt: "center",
      animationDuration: 1800,
      autoplay: !this.isSingle ? 7000 : false, // スライドが 1つ以下の場合は autoplay しない
      gap: 0,
      perView: 1,
      startAt: 0,
      peek: 0,
      breakpoints: { 960: { peek: 0 } },
    });

    this.addEvent();
  }

  /**
   * イベント初期化
   *
   * @memberof HeroSlider
   */
  addEvent() {
    /**
     * glide events
     * https://glidejs.com/docs/events/
     */

    this.glide
      .on("mount.after", () => {
        // this.updatedPeek();
        this.labelDisplay();
        this.wrapper.classList.add("js-glide-active");
      })
      .on("run", () => this.labelDisplay())
      .mount();

    this.observer = new ResizeObserver((entries) => {
      if (entries[0].contentBoxSize) {
        this.glide.update()
      }
    });
    this.observer.observe( this.slider );

    if (this.isSingle) {
      this.glide._c.Swipe.disable();
    }
    /**
     * imagesloaded events
     * https://imagesloaded.desandro.com/#events
     */
    this.imgLoad
      .on("progress", (instance, image) => {
        if (image.img) image.img.classList.add("js-is-image-loaded");
      })
      .on("done", () => {
        this.wrapper.classList.add("js-is-all-image-loaded");
        this.glide.update()
        // this.updatedPeek();
      });

    /**
     * window events
     */
    // const peekThrottle = throttle(() => this.updatedPeek(), 100);
    // window.addEventListener("resize", peekThrottle);
    // window.addEventListener("load", () => this.updatedPeek());
  }

  /**
   * スライドの data-slide-is-label 属性をチェックし、ラベル表示を ON / OFF
   *
   * @returns
   * @memberof HeroSlider
   */
  labelDisplay() {
    const index = `[data-slide-index="${this.glide.index}"]`;
    const slide = this.slider.querySelector(index);

    if (!slide) return;

    const isDisplay = slide.getAttribute("data-slide-is-label") === "1";

    if (isDisplay) {
      this.wrapper.classList.add("js-is-label-active");
    } else {
      this.wrapper.classList.remove("js-is-label-active");
    }
  }

  /**
   * スライダーの peek 幅補正
   * https://glidejs.com/docs/options/#peek
   *
   * @memberof HeroSlider
   */
  updatedPeek() {
    const isLg = window.matchMedia(mq.lg).matches;
    let peekSize = isLg ? window.innerWidth * (0.165 / 2) : 0;

    if (this.isSingle) {
      peekSize = 0;
    }

    this.glide.update({ peek: { before: peekSize, after: peekSize } });
  }
}

export default HeroSlider;
